
const categories = [
    {name: 'udogodnienia',
        types: [
            'łazienka w pokoju', 'kuchnia/aneks kuchenny', 'Wi-Fi', 'parking', 'akceptacja zwierząt', 'plac zabaw', 'basen/kąpielisko'
        ],
    },
    {name: 'wyżywienie',
    types: [
        'śniadanie', 'pełne wyżywienie', 'własne wyżywienie'
    ],
    },
    {name: 'atrakcje',
    types: [
        'piesze wędrówki', 'grzyby', 'narty', 'pływanie', 'jazda konna', 'wędkowanie'
    ],
    },
]

export default categories;
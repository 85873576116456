<template>
    <div>
        <div>Wpisz hasło</div>
        <input type="text" v-model="insertedPassword"/>
        <button @click="confrmPassword">Zatwierdź</button>
    </div>
</template>
<script>
export default {
 data() {
    return {
        insertedPassword: ""
    }
 },
 methods: {
    confrmPassword() {
        if(this.insertedPassword == "99287") {
            this.$router.push('/TheBeginning');
        }
    }
 }
}
</script>
<style scoped>
div {
    margin: 20px;
}
input {
    margin: 10px;
}
</style>
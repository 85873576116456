import { createRouter, createWebHistory } from 'vue-router';
//import VueRouter from 'vue-router'

import FirstPage from './components/FirstPage.vue';
import TheBeginning from './components/TheBeginning.vue';
import ProductsForm from './components/ProductsForm.vue';
import HotelsForm from './components/HotelsForm.vue';
import RehabilitationForm from './components/RehabilitationForm.vue';


export const router = createRouter({
    mode: 'hash',
    history: createWebHistory(),
    routes: [
        {path: '/', name: 'FirstPage', component: FirstPage},
        {path: '/rehabilitationForm', name: 'RehabilitationForm', component: RehabilitationForm},
        {path: '/productsForm', name: 'ProductsForm', component: ProductsForm},
        {path: '/hotelsForm', name: 'HotelsForm', component: HotelsForm},
        {path: '/TheBeginning', name: 'TheBeginning', component: TheBeginning},
    ]
});

//export default {router};
<template>
    <h2>Wprowadzanie klientów do bazy danych Mendel Helps</h2>
    <div class="box-container">
        <router-link to="/productsForm">
            <div class="box" :style="{ backgroundImage: 'url(' + backgrounds.img1 + ')' }">
                <h3>Baza klientów <span>Produkty</span></h3>
            </div>
        </router-link>
        <router-link to="/hotelsForm">
            <div class="box" :style="{ backgroundImage: 'url(' + backgrounds.img2 + ')' }">
                <h3>Baza klientów <span>Wypoczynek</span></h3>
            </div>
        </router-link>
        <router-link to="/rehabilitationForm">
            <div class="box" :style="{ backgroundImage: 'url(' + backgrounds.img3 + ')' }">
                <h3>Baza klientów <span>Podreperuj zdrowie</span></h3>
            </div>
        </router-link>
    </div>
</template>
<script>
import imgProducts from '../photos/produkty.jpeg';
import imgHotels from '../photos/hotele.jpeg';
import imgHealth from '../photos/sanatoria.jpeg';
    export default {
        data() {
            return {
                backgrounds: {
                    img1: imgProducts,
                    img2: imgHotels,
                    img3: imgHealth
                }
            }
        }
    }
</script>
<style scoped>
.box-container {
    display: flex;
    justify-content: center;
    align-items: center;
}
.box {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 350px;
    height: 230px;
    margin: 15px;
    border-radius: 20px;
    background-size: cover;
    background-position: center;
}
.box h3 {
    font-size: 20px;
    padding: 5px 10px;
    background-color: white;
    border-radius: 10px;
}
.box h3:hover {
    cursor: pointer;
    background-color: #80bbfc;
}
</style>
<template>
<div class="form-container">
  <div>
    <h2>Baza danych wypoczynek</h2>
  </div>
  <form @submit="addCompany" enctype="multipart/form-data">
    <!--<form action="upload.php" method="post" enctype="multipart/form-data">-->
    <div>
      <div>
        <label>Nazwa producenta lub imię i nazwisko</label><br/>
        <input type="text" v-model="company.prodName" />
      </div>
      <div>
        <label>Ulica, nr.domu</label>
        <input type="text" v-model="prodStreet" />
      </div>
      <div>
        <label>Kod pocztowy</label>
        <input
          placeholder="12-345"
          type="text"
          v-model="prodZipCode"
          @blur="validateZipCode(this.prodZipCode)"
        />
      </div>
      <div class="error">
        <p>{{ error.zipcode }}</p>
      </div>
      <div>
        <label>Miasto</label>
        <input type="text" v-model="prodCity" />
      </div>
      <div>
        <label for="woj">Województwo</label>
        <select id="woj" v-model="company.woj">
            <option value="dolnośląskie">dolnośląskie</option>
            <option value="kujawsko-pomorskie">kujawsko-pomorskie</option>
            <option value="lubelskie">lubelskie</option>
            <option value="łódzkie">łódzkie</option>
            <option value="małopolskie">małopolskie</option>
            <option value="mazowieckie">mazowieckie</option>
            <option value="opolskie">opolskie</option>
            <option value="podkarpackie">podkarpackie</option>
            <option value="podlaskie">podlaskie</option>
            <option value="pomorskie">pomorskie</option>
            <option value="śląskie">śląskie</option>
            <option value="świętokrzyskie">świętokrzyskie</option>
            <option value="łódzkie">łódzkie</option>
            <option value="warmińsko-mazurskie">warmińsko-mazurskie</option>
            <option value="wielkopolskie">wielkopolskie</option>
            <option value="zachodniopomorskie">zachodniopomorskie</option>
        </select>
      </div>
      <div>
        <label>Adres mailowy</label>
        <input type="text" v-model="company.mail" @blur="validateEmail(this.company.mail)" placeholder="example@domain.com"/>
      </div>
        <div class="error">
        <p>{{ error.email }}</p>
      </div>
      <div>
        <label>Telefon komórkowy</label>
        <input type="text" v-model="company.tel1" @blur="validateMobilePhone(this.company.tel1)" placeholder="123 456 789"/>
      </div>
        <div class="error">
        <p>{{ error.mobile }}</p>
      </div>
           <div>
        <label>Telefon komórkowy 2</label>
        <input type="text" v-model="company.tel3" @blur="validateMobilePhone1(this.company.tel3)" placeholder="123 456 789"/>
      </div>
        <div class="error">
        <p>{{ error.mobile2 }}</p>
      </div>
        <div>
        <label>Telefon stacjonarny</label>
        <input type="text" v-model="company.tel2" @blur="validatePhone(this.company.tel2)" placeholder="12 345 67 89"/>
      </div>
        <div class="error">
        <p>{{ error.phone }}</p>
      </div>
      <div>
        <label>Adres strony</label>
        <input type="text" v-model="company.webSite" @blur="validateWebSite(this.company.webSite)" placeholder="https://example.pl"/>
      </div>
      <div class="error">
        <p>{{ error.site }}</p>
      </div>
      <div>
        <label>Facebook</label>
        <input type="text" v-model="company.facebook" @blur="validateFacebook(this.company.facebook)" placeholder="https://www.facebook.com/example"/>
      </div>
      <div class="error">
        <p>{{ error.facebook }}</p>
      </div>
      <div>
        <label>Instagram</label>
        <input type="text" v-model="company.instagram" @blur="validateInstagram(this.company.instagram)" placeholder="https://www.instagram.com/example"/>
      </div>
      <div class="error">
        <p>{{ error.instagram }}</p>
      </div>
        <div>
        <label>Opis firmy:</label>
        <textarea v-model="company.description" rows="3" cols="55" maxlength="600"></textarea>
      </div>
            <div class="onefilter-container">
                <h3>Udogodnienia</h3>
                <div v-for="type in this.facilities" :key="type" class="check-box" >
                    <label class="container">{{ type }}
                        <input
                        type="checkbox"
                        id="subcategory"
                        name="facilities"
                        :value="type"
                    />
                        <span class="checkmark"></span>
                    </label><br/>
                </div>
            </div>
             <div class="onefilter-container">
                <h3>Wyżywienie</h3>
                <div v-for="type in this.food" :key="type" class="check-box" >
                    <label class="container">{{ type }}
                        <input
                        type="checkbox"
                        id="subcategory"
                        name="food"
                        :value="type"
                    />
                        <span class="checkmark"></span>
                    </label><br/>
                </div>
            </div>
            <div class="onefilter-container">
                <h3>Atrakcje</h3>
                <div v-for="type in this.fun" :key="type" class="check-box" >
                    <label class="container">{{ type }}
                        <input
                        type="checkbox"
                        id="subcategory"
                        name="fun"
                        :value="type"
                    />
                        <span class="checkmark"></span>
                    </label><br/>
                </div>
            </div>
      <div class="desc-logo"><p>Wybierz logo</p></div>
      <div class="inputFile-container">
        <input type="file" v-on:change="handleFileUpload($event)" />
      </div>
      <div>
      <div>
        <label class="add-distinctions">Dodatkowe wyróżnienia: </label>
            <div class="check-box">
                <label class="container-checkbox">Pogrubienie czcionki i dodanie adresu strony internetowej.
                    <input type="checkbox" id="font" name="font" value="fontAndSite"/>
                    <span class="checkmark"></span>
                </label><br>
            </div>
            <div class="check-box">
                <label class="container-checkbox">Dodanie logo(sygnet), zdjęcia lub grafiki.
                    <input type="checkbox" id="logo" name="logo" value="logo"/>
                    <span class="checkmark"></span>
                </label><br>
            </div>
            <div class="check-box">
                <label class="container-checkbox">Twoja firma na pierwszych 30 miejscach.
                    <input type="checkbox" id="firstPlace" name="firstPlace" value="firstPlace"/>
                    <span class="checkmark"></span>
                </label><br>
            </div>
      </div>
      </div>
      <div class="desc"><p>Wyślij i zacznij wprowadzać nowego klienta</p></div>
      <input type="submit" value="Wyślij"/>
    </div>
    <div class="response-alert-OK"> {{ responseAlertOK }}</div>
    <div class="response-alert-error"> {{ responseAlertError }}</div>
  </form>
  <div class="table-container">
    <table>
      <tr>
        <th>Nazwa</th>
        <th>Województwo</th>
        <th>Adres</th>
        <th>tel</th>
        <th>tel2</th>
        <th>tel3</th>
        <th>adres e-mail</th>
        <th>strona www</th>
        <th>facebook</th>
        <th>instagram</th>
        <th>udogodnienia</th>
        <th>wyżywienie</th>
        <th>atrakcje</th>
      </tr>
      <tr v-for="company in this.baseHotels"
          v-bind:key="company.id">
          <td>{{company.nazwa_producenta}}</td>
          <td>{{company.województwo}}</td>
          <td>{{company.adres_producenta}}</td>
          <td>{{company.tel1}}</td>
          <td>{{company.tel2}}</td>
          <td>{{company.tel3}}</td>
          <td>{{company.adres_mailowy}}</td>
          <td>{{company.adres_strony}}</td>
          <td>{{company.facebook}}</td>
          <td>{{company.instagram}}</td>
          <td>{{company.udogodnienia}}</td>
          <td>{{company.wyzywienie}}</td>
          <td>{{company.atrakcje}}</td>
      </tr>
    </table>
  </div>
</div>
</template>
<script>
import axios from "axios";
import CompaniesDataService from "../GetCompanies.js";
import categories from "../categoriesHotels.js";
export default {
  data() {
    return {
      baseHotels: [],
      company: {
        description: '',
        facilities: [],
        food:[],
        fun:[],
        prodName: "",
        prodAdress: "",
        woj: "",
        mail: "",
        tel1: "",
        tel2: "",
        tel3: "",
        webSite: "",
        facebook: "",
        instagram: "",
        choosenFood: [],
        choosenFacilities: [],
        choosenFun: [],
        category: "",
        subcategories: [],
      },
      prodStreet: "",
      prodZipCode: "",
      prodCity: "",
      file: "",
      allCategories: categories,
      chosenCategory: "",
      typesFromCategory: [],
      error: {
        zipcode: "",
        email: "",
        mobile: "",
        phone: "",
        mobile2: "",
        facebook: "",
        instagram: ""
      },
      sendForm: true,
      responseAlertOK: "",
      responseAlertError: ""
    };
  },
  created() {
        var facilities = this.allCategories.find((el)=>el.name == 'udogodnienia');
        this.facilities = facilities.types;
        var food = this.allCategories.find((el)=>el.name == 'wyżywienie');
        this.food = food.types;
        var fun = this.allCategories.find((el)=>el.name == 'atrakcje');
        this.fun = fun.types;
        this.fetchComapnies();
  },
  methods: {
        fetchComapnies() {
        axios
          .get(`./fetchHotels.php`)
          .then((response) => response)
          .then((res) => {
            this.baseHotels = res.data;
            console.log("companies");
            console.log(this.baseHotels);
          })
          .catch((error) => {
            console.error("Error:", error);
            console.log("server is down!!");
          });
    },
    addCompany() {
      this.company.prodAdress =
        this.prodStreet + ", " + this.prodZipCode + " " + this.prodCity;
      console.log(this.company.prodAdress);
      if (this.sendForm) {
        var food = [];
        var facilities = [];
        var fun = [];
        event.preventDefault();
        let checkboxesFood = document.getElementsByName("food");
        for (var i = 0; i < checkboxesFood.length; i++) {
          if (checkboxesFood[i].checked) {
            food.push(checkboxesFood[i].value);
            food.toString();
            console.log(food);
          }
        }
        let checkboxesFacilities = document.getElementsByName("facilities");
        for (var j = 0; j < checkboxesFacilities.length; j++) {
          if (checkboxesFacilities[j].checked) {
            facilities.push(checkboxesFacilities[j].value);
            facilities.toString();
            console.log(facilities);
          }
        }
        let checkboxesFun = document.getElementsByName("fun");
        for (var k = 0; k < checkboxesFun.length; k++) {
          if (checkboxesFun[k].checked) {
            fun.push(checkboxesFun[k].value);
            fun.toString();
            console.log(fun);
          }
        }
        let fontDistinction = 0;
        let font = document.getElementsByName("font");
        console.log(font);
        //let checkedSubcategories = [];
          if (font[0].checked) {
            fontDistinction = 1;
          } else {
            fontDistinction = 0;
          }
          console.log(fontDistinction);

        let logoDistinction = 0;
        let logo = document.getElementsByName("logo");
        console.log(logo);
        //let checkedSubcategories = [];
          if (logo[0].checked) {
            logoDistinction = 1;
          } else {
            logoDistinction = 0;
          }
          console.log(logoDistinction);

        let firstPlaceDistinction = 0;
        let firstPlace = document.getElementsByName("firstPlace");
        console.log(firstPlace);
        //let checkedSubcategories = [];
          if (firstPlace[0].checked) {
            firstPlaceDistinction = 1;
          } else {
            firstPlaceDistinction = 0;
          }
          console.log(firstPlaceDistinction);

        const formData = new FormData();
        formData.append("woj", this.company.woj);
        formData.append("prodName", this.company.prodName);
        formData.append("prodAdress", this.company.prodAdress);
        formData.append("mail", this.company.mail);
        formData.append("tel1", this.company.tel1);
        formData.append("tel2", this.company.tel2);
        formData.append("tel3", this.company.tel3);
        formData.append("webSite", this.company.webSite);
        formData.append("facebook", this.company.facebook);
        formData.append("instagram", this.company.instagram);
        formData.append("food", food);
        formData.append("facilities", facilities);
        formData.append("fun", fun);
        formData.append("opis", this.company.description);
        formData.append("wyrCzcionka", fontDistinction);
        formData.append("wyrLogo", logoDistinction);
        formData.append("wyrPierwszeMiesca", firstPlaceDistinction);
        if(this.file !="") {
          formData.append("file", this.file, this.file.name);
        }

        console.log(formData.getAll("dzemy"));
        //CompaniesDataService.createCompany(formData);

        axios.post("../uploadHotels.php", formData, {}).then((res) => {
          console.log(res);
          if(res.status !== 200) {
          this.responseAlertError = "Coś poszło nie tak. Sprawdź rozmiar zdjęcia i poprawność danych.";
            setTimeout(()=> {
              this.responseAlertError = ""
            }, 3000);
          } else if(res.status == 200) {
            this.responseAlertOK = "Zapisano w bazie"
            this.fetchComapnies()
            setTimeout(()=> {
              this.responseAlertOK = ""
              location.reload();
            }, 4000
            )
          }
        });
      }
    },
    submitAddNewCategory() {
       this.company.prodAdress =
        this.prodStreet + ", " + this.prodZipCode + " " + this.prodCity;
      console.log(this.company.prodAdress);
      if (this.sendForm) {
        var checkedSubcategories = [];
        event.preventDefault();
        let checkboxes = document.getElementsByName("subcategory");
        //let checkedSubcategories = [];
        for (var i = 0; i < checkboxes.length; i++) {
          if (checkboxes[i].checked) {
            checkedSubcategories.push(checkboxes[i].value);
            checkedSubcategories.toString();
          }
        }
        const formData = new FormData();
        formData.append("woj", this.company.woj);
        formData.append("prodName", this.company.prodName);
        formData.append("prodAdress", this.company.prodAdress);
        formData.append("mail", this.company.mail);
        formData.append("tel1", this.company.tel1);
        formData.append("tel2", this.company.tel2);
        formData.append("tel3", this.company.tel3);
        formData.append("webSite", this.company.webSite);
        formData.append("facebook", this.company.facebook);
        formData.append("instagram", this.company.instagram);
        formData.append("category", this.chosenCategory);
        formData.append("subcat", checkedSubcategories);
        if(this.file !="") {
          formData.append("file", this.file, this.file.name);
        }

        console.log(formData.getAll("dzemy"));
        //CompaniesDataService.createCompany(formData);
        this.chosenCategory = '';
        axios.post("../upload.php", formData, {}).then((res) => {
          console.log(res);
          if(res.status !== 200) {
          this.responseAlertError = "Coś poszło nie tak. Sprawdź rozmiar zdjęcia i poprawność danych.";
            setTimeout(()=> {
              this.responseAlertError = ""
            }, 3000);
          } else if(res.status == 200) {
            this.responseAlertOK = "Zapisano w bazie"
            setTimeout(()=> {
              this.responseAlertOK = ""
              location.reload();
            }, 4000
            )
          }
        });
      }
    },
    submitFile() {
      let formData = new FormData();
      formData.append("file", this.file);
      CompaniesDataService.uploadLogo(formData);
    },
    handleFileUpload(event) {
      this.file = event.target.files[0];
    },
    validateZipCode(code) {
      const regex = /^[0-9]{2}-[0-9]{3}$/;
      if (!regex.test(code)) {
        this.error.zipcode = "Wprowadź poprawny kod pocztowy.";
        this.sendForm = false;
      } else {
        this.error.zipcode = "";
        this.sendForm  = true;
      }
    },
    validateEmail(email) {
        const regex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/; //eslint-disable-line
         if (!regex.test(email)) {
                this.error.email = "Wpisz poprawny adres email.";
                this.sendForm = false;
            } else {
                this.error.email = " ";
                this.sendForm  = true;
            }
        },
    validateMobilePhone(phone) {
         const regex = /^\d{3} \d{3} \d{3}$/;
         if (!regex.test(phone)) {
                this.error.mobile = "Wpisz numer telefonu komórkowego w formacie 'XXX XXX XXX'.";
                this.sendForm = false;
            } else {
                this.error.mobile = " ";
                this.sendForm  = true;
            }
    },
        validateMobilePhone1(phone) {
         const regex = /^\d{3} \d{3} \d{3}$/;
         if (!regex.test(phone)) {
                this.error.mobile2 = "Wpisz numer telefonu komórkowego w formacie 'XXX XXX XXX'.";
                this.sendForm = false;
            } else {
                this.error.mobile2 = " ";
                this.sendForm  = true;
            }
    },
    validatePhone(phone1) {
            const regex = /^\+?([0-9]{2})[-. ]?([0-9]{3})[-. ]?([0-9]{2})[-. ]?([0-9]{2})$/;
         if (!regex.test(phone1)) {
                this.error.phone = "Wpisz numer telefonu w formacie 'XX XXX XX XX'.";
                this.sendForm = false;
            } else {
                this.error.phone = " ";
                this.sendForm  = true;
            }
    },
        validateWebSite(site) {
            const regex = /(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z]{2,}(\.[a-zA-Z]{2,})(\.[a-zA-Z]{2,})?\/[a-zA-Z0-9]{2,}|((https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z]{2,}(\.[a-zA-Z]{2,})(\.[a-zA-Z]{2,})?)|(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z0-9]{2,}\.[a-zA-Z0-9]{2,}\.[a-zA-Z0-9]{2,}(\.[a-zA-Z0-9]{2,})?/g;
         if (!regex.test(site)) {
                this.error.site = "Wpisz poprawny adres strony.";
                this.sendForm = false;
            } else {
                this.error.site = " ";
                this.sendForm  = true;
            }
    },
      validateFacebook(facebookLink) {
            const regex = /(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z]{2,}(\.[a-zA-Z]{2,})(\.[a-zA-Z]{2,})?\/[a-zA-Z0-9]{2,}|((https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z]{2,}(\.[a-zA-Z]{2,})(\.[a-zA-Z]{2,})?)|(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z0-9]{2,}\.[a-zA-Z0-9]{2,}\.[a-zA-Z0-9]{2,}(\.[a-zA-Z0-9]{2,})?/g;
         if (!regex.test(facebookLink)) {
                this.error.facebook = "Wpisz poprawny adres strony facebook.";
                this.sendForm = false;
            } else {
                this.error.facebook = " ";
                this.sendForm  = true;
            }
    },
          validateInstagram(instaLink) {
            const regex = /(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z]{2,}(\.[a-zA-Z]{2,})(\.[a-zA-Z]{2,})?\/[a-zA-Z0-9]{2,}|((https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z]{2,}(\.[a-zA-Z]{2,})(\.[a-zA-Z]{2,})?)|(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z0-9]{2,}\.[a-zA-Z0-9]{2,}\.[a-zA-Z0-9]{2,}(\.[a-zA-Z0-9]{2,})?/g;
         if (!regex.test(instaLink)) {
                this.error.instagram = "Wpisz poprawny adres strony instagram.";
                this.sendForm = false;
            } else {
                this.error.instagram = " ";
                this.sendForm  = true;
            }
    }
  },
  watch: {
    chosenCategory() {
      let category = this.chosenCategory;
      let typesArray = this.allCategories.find(
        (option) => option.name == category
      );
      this.typesFromCategory = typesArray;
    },
  },
};
</script>

<style scoped>
.form-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
form {
  margin: 20px;
  width: 600px;
}
div {
  margin: 25px;
}
label {
  font-size: 16px;
  float:left;
}
input {
  width: 100%;
  font-size: 19px;
  padding: 5px;
}
input[type='checkbox'] {
  width: auto;
  margin: 0;
  padding:0;
}
option {
  width: 100%;
  font-size: 19px;
  padding: 5px;
}
select {
  width: 100%;
  padding: 5px;
  font-size: 19px;
}
.error {
  color: red;
}
.response-alert-OK {
  color: green;
  font-size: 24px;
}
.response-alert-error {
  color: red;
  font-size: 24px;
}
input[type="submit"], button {
  font-size: 19px;
  background-color: #3084E2;
  padding: 10px;
  color: white;
  border: 1px solid #3084E2;
  border-radius: 15px;
  width: 100%;
  margin: 0 30px 15px 20px;
}
input[type="submit"]:hover, button:hover {
  background-color: #164b88;
  cursor: pointer;
}
.inputFile-container {
  margin: 0 20px;
}
.desc {
  margin: 0;
}
.desc-logo {
  float: left;
   margin: 0 20px;;
   font-weight: 600;
}
.desc p{
  margin: 3px;
}

/*styling checkboxes*/

.container {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.container-checkbox {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 0;
  cursor: pointer;
  font-size: 18px;
  float: left;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #eee;
}

/* On mouse-over, add a grey background color */
.container:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.container input:checked ~ .checkmark {
  background-color: #2196F3;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.container input:checked ~ .checkmark:after {
  display: block;
}
.container .checkmark:after {
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
.check-box {
  width: 100%;
}
/* Hide the browser's default checkbox */
.container-checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  margin:0;
  height: 25px;
  width: 25px;
  background-color: #eee;
}

/* On mouse-over, add a grey background color */
.container-checkbox:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.container-checkbox input:checked ~ .checkmark {
  background-color: #2196F3;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.container-checkbox input:checked ~ .checkmark:after {
  display: block;
}
.container-checkbox .checkmark:after {
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
.check-box {
    margin: 10px 0 20px 0;
    padding: 10px;
     width: 100%;
}
.add-distinctions {
  margin-bottom: 15px;
}
.table-container {
  width: 100vw;
  overflow-x: scroll;
}
table {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
  font-size: 15px;
}

table td, table th {
  border: 1px solid #ddd;
  padding: 8px;
  max-width: 100px;
  text-wrap: wrap;
  word-wrap: break-word;
}

table tr:nth-child(even){background-color: #f2f2f2;}

table tr:hover {background-color: #ddd;}

table th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: #164b88;
  color: white;
}
</style>
